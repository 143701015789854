<template>
  <b-card class="text-center cardModel">
    <div class="sous-card" :class="{ loaderData: loaderData }">
      <slot name="header"></slot>
      <slot name="title"> </slot>
      <slot name="body"></slot>
    </div> </b-card
></template>

<script>
export default {
  props: {
    loaderData: {
      type: Boolean,
      required: false
    }
  }
};
</script>

<!-- <style lang="scss" scoped>
.cardModel {
  width: 100%;
  background-color: #f6f5fb;
  box-shadow: -2px 0px 6px #00000017;
  position: relative;
  top: -5px;
  border-radius: 28px;
  height: 100%;
  border: none;
  .sous-card {
    background-color: #fff;
    border-radius: 28px;
    padding: 12px;
    &.loaderData {
      background-color: transparent;
      padding: 0px;
    }
  }
}
</style>
<style lang="scss">
.cardModel .card-body {
  width: auto;
  padding: 5px;
  min-height: 61px;
}
</style> -->
